import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Theme, ThemeProps } from '@theme/base';
import { ZIndex } from '@styles/z-index';
import { mq } from '@styles/breakpoints';
import { TestId } from '@components/test-ids';
import { SearchTabsBar } from '@components/search/SearchTabsBar';

/* ***************** *
 *       Types       *
 * ***************** */
export interface HomeSearchContainerProps {
  theme?: Theme;
  children?: JSX.Element | Array<JSX.Element>;
  isWhiteLabel: boolean;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const Container = styled.div({
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0) 50%);',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative'
});

const SearchWrapper = styled.div(({ theme }: ThemeProps) => ({
  background: theme.custom.colors.group10.lighter,
  width: '100%',
  zIndex: ZIndex.CONTENT,
  boxShadow: `rgba(0, 0, 0, 0.2) 0px 2px 4px`,
  border: `1px solid ${theme.custom.colors.group10.base}`,
  [mq.small]: {
    margin: '-50px auto 0 auto',
    maxWidth: theme.custom.contentSizes.maxContentWidth
  },
  [mq.large]: {
    borderRadius: 10
  }
}));

/* *********************** *
 *    TemplateComponent    *
 * *********************** */

export const HomeSearchContainerComponent = ({ children, isWhiteLabel }: HomeSearchContainerProps) => {
  return (
    <Container data-testid={TestId.homeSearch}>
      {children}
      {!isWhiteLabel && (
        <SearchWrapper>
          <SearchTabsBar isOverlay />
        </SearchWrapper>
      )}
    </Container>
  );
};

export const HomeSearchContainer = withTheme(HomeSearchContainerComponent);
