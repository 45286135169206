import React from 'react';
import Slider, { Settings } from 'react-slick';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Theme } from '@theme/base';
import { CarouselTile as CarouselTileModel } from '@model/content';
import { useResize } from '@components/hooks';
import { TestId } from '@components/test-ids';
import { HomeCarouselTile } from './HomeCarouselTile';

/* ***************** *
 *       Types       *
 * ***************** */
export interface HomeCarouselProps {
  tiles: Array<CarouselTileModel>;
  fillScreen?: boolean;
  theme?: Theme;
}
//
/* ***************** *
 *       Styles      *
 * ***************** */
const Container: any = styled.div({
  position: 'relative',
  ['.slick-track']: {
    display: 'flex',
    alignItems: 'stretch'
  }
});

const Dots = () => (dots) => (
  <div style={{ bottom: 32 }}>
    <ul style={{ margin: 0, padding: 0 }}> {dots} </ul>
  </div>
);

/* *********************** *
 *    HomeCarouselComponent    *
 * *********************** */

export const HomeCarouselComponent = ({ tiles }: HomeCarouselProps) => {
  const [isDesktop] = useResize();

  const renderAllTiles = (tiles: Array<CarouselTileModel>) =>
    (tiles || []).map((tile) => <HomeCarouselTile key={tile.heading} {...tile} imagesOnly fillScreen />);

  const settings: Settings = {
    appendDots: Dots(),
    arrows: false,
    dots: false,
    fade: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    autoplaySpeed: isDesktop ? 10000 : 5000
  };

  return (
    <>
      <Container data-testid={TestId.homepage.carousel}>
        <Slider {...settings} autoplay>
          {renderAllTiles(tiles)}
        </Slider>
      </Container>
    </>
  );
};

export const HomeCarousel = withTheme(HomeCarouselComponent);
