import { Name } from '@model/iceberg';
import { DestinationLink } from '@components/common/next-link';
import React from 'react';
import { BaseCard } from '@components/recently-viewed/card/BaseCard';
import { useI18NextContext } from '@components/hooks';
import { HotelTile } from '@model/iceberg/hotel-content/hotel-content';
import { getSizes } from '@styles/breakpoints';

export interface RecentlyViewedCardProps {
  hotel?: HotelTile;
}

export const FITRecentlyViewedCard = ({ hotel }: RecentlyViewedCardProps) => {
  const t: any = useI18NextContext();

  if (!hotel) return null;
  const { featuredImage, place } = hotel;
  const name: Name = hotel.place.region || hotel.place.country.name;
  const sizes = getSizes([100, 50, 25], '294px');

  return (
    <DestinationLink path={place.hotel!.path}>
      <BaseCard
        subtitle={`${name.display} ${t('common__label--holidays')}`}
        title={place.hotel?.display || ''}
        image={featuredImage.url}
        sizes={sizes}
      />
    </DestinationLink>
  );
};
